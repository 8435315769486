import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
} from '@mantine/core';
import {
  SpecialtyFields,
  FormValues,
  specialties,
} from '../types/specialties.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import CustomDateInput from './CustomDateInput';

interface FormProps {
  memberId: number;
}

const SpecialtyForm: React.FC<FormProps> = ({ memberId }) => {
  const { control, register, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>({
      defaultValues: {
        primaryspecialty: {
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          boardcertified: 'No',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          initialcertificationdate: null,
          certifyingboard: '',
        },
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryspecialties',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<FormValues>();
  const httpClient = useHttpClient();

  const fetchSpecialties = async () => {
    try {
      const response = await httpClient.get(`/api/specialties/${memberId}`);
      const fetchedSpecialties: SpecialtyFields[] = response.data;

      if (fetchedSpecialties.length === 0) {
        console.log('No specialties found for this member.');
        return;
      }

      const primarySpecialty = fetchedSpecialties.find(
        (s) => s.type === 'primary',
      );
      const secondarySpecialties = fetchedSpecialties
        .filter((s) => s.type === 'secondary')
        .sort((a, b) => a.id! - b.id!);

      reset({
        primaryspecialty: primarySpecialty || {
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          boardcertified: '',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          initialcertificationdate: null,
          certifyingboard: '',
        },
        secondaryspecialties: secondarySpecialties,
      });

      setInitialData({
        primaryspecialty: primarySpecialty!,
        secondaryspecialties: secondarySpecialties,
      });

      console.log('Specialties fetched and form reset.');
    } catch (error) {
      console.error('Error fetching specialties:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch specialties',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchSpecialties();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    console.log('submit form values', data);

    const createSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.post('/api/specialties', specialty);
      return response.data;
    };

    const updateSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.put(
        `/api/specialties/${specialty.id}`,
        specialty,
      );
      return response.data;
    };

    const deleteSpecialty = async (id: number) => {
      await httpClient.delete(`/api/specialties/${id}`);
      console.log('delete response');
    };

    const initialSecondaryIds = new Set(
      initialData?.secondaryspecialties.map((s) => s.id),
    );
    const currentSecondaryIds = new Set(
      data.secondaryspecialties.map((s) => s.id),
    );
    const deletedIds = [...initialSecondaryIds].filter(
      (id) => !currentSecondaryIds.has(id),
    );

    const primaryTask =
      !data.primaryspecialty.id || data.primaryspecialty.id === 0
        ? createSpecialty(data.primaryspecialty)
        : updateSpecialty(data.primaryspecialty);

    const secondaryCreationTasks = data.secondaryspecialties
      .filter((s) => !s.id || s.id === 0)
      .map((s) => createSpecialty(s));
    const secondaryUpdateTasks = data.secondaryspecialties
      .filter((s) => s.id && s.id > 0 && initialSecondaryIds.has(s.id))
      .map((s) => updateSpecialty(s));

    const secondaryDeleteTasks = deletedIds.map(deleteSpecialty);

    try {
      const results = await Promise.all([
        primaryTask,
        ...secondaryCreationTasks,
        ...secondaryUpdateTasks,
        ...secondaryDeleteTasks,
      ]);
      console.log('Results:', results);
      fetchSpecialties();

      notifications.show({
        title: 'Success',
        message: 'Specialties updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting specialty form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit specialty',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const primaryspecialty = watch(
    'primaryspecialty.specialty',
  ) as keyof typeof specialties;
  const secondaryspecialties = fields.map(
    (_, index) =>
      watch(
        `secondaryspecialties.${index}.specialty`,
      ) as keyof typeof specialties,
  );

  useEffect(() => {
    setValue('primaryspecialty.subspecialty', []);
  }, [primaryspecialty, setValue]);

  useEffect(() => {
    secondaryspecialties.forEach((specialty, index) => {
      setValue(`secondaryspecialties.${index}.subspecialty`, []);
    });
  }, [secondaryspecialties, setValue]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md">
          <Group>
            <h3>Primary Specialty</h3>
          </Group>
          <Controller
            name="primaryspecialty.specialty"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Specialty"
                data={Object.keys(specialties)}
                clearable
                searchable
              />
            )}
          />
          <Controller
            name="primaryspecialty.subspecialty"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="Subspecialty"
                data={primaryspecialty ? specialties[primaryspecialty] : []}
                clearable
                searchable
              />
            )}
          />
          <Controller
            name="primaryspecialty.boardcertified"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Board Certified?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          {watch('primaryspecialty.boardcertified') === 'Y' && (
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="Certification Number"
                  {...register('primaryspecialty.certificationnumber')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  required={false}
                  control={control}
                  name="primaryspecialty.initialcertificationdate"
                  label="Initial Certification Date"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  required={false}
                  control={control}
                  name="primaryspecialty.recertificationdate"
                  label="Last Recertification Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  required={false}
                  control={control}
                  name="primaryspecialty.certificationexpiration"
                  label="Expiration Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Certifying Board"
                  {...register('primaryspecialty.certifyingboard')}
                />
              </Grid.Col>
            </Grid>
          )}
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Secondary Specialty {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Controller
                name={`secondaryspecialties.${index}.specialty`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Specialty"
                    data={Object.keys(specialties)}
                    clearable
                    searchable
                  />
                )}
              />
              <Controller
                name={`secondaryspecialties.${index}.subspecialty`}
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    label="Subspecialty"
                    data={
                      secondaryspecialties[index]
                        ? specialties[secondaryspecialties[index]]
                        : []
                    }
                    clearable
                    searchable
                  />
                )}
              />
              <Controller
                name={`secondaryspecialties.${index}.boardcertified`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Board Certified?"
                    data={[
                      { value: 'Y', label: 'Yes' },
                      { value: 'N', label: 'No' },
                    ]}
                    clearable
                  />
                )}
              />
              {watch(`secondaryspecialties.${index}.boardcertified`) ===
                'Y' && (
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Certification Number"
                        {...register(
                          `secondaryspecialties.${index}.certificationnumber`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <CustomDateInput
                        required={false}
                        control={control}
                        name={`secondaryspecialties.${index}.initialcertificationdate`}
                        label="Initial Certification Date"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <CustomDateInput
                        required={false}
                        control={control}
                        name={`secondaryspecialties.${index}.recertificationdate`}
                        label="Last Recertification Date (If Applicable)"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <CustomDateInput
                        required={false}
                        control={control}
                        name={`secondaryspecialties.${index}.certificationexpiration`}
                        label="Expiration Date (If Applicable)"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Certifying Board"
                        {...register(
                          `secondaryspecialties.${index}.certifyingboard`,
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                )}
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              append({
                id: 0,
                memberId: memberId,
                type: 'secondary',
                specialty: null,
                subspecialty: [],
                boardcertified: 'No',
                certificationnumber: '',
                certificationexpiration: null,
                recertificationdate: null,
                initialcertificationdate: null,
                certifyingboard: '',
              })
            }
          >
            Add Secondary Specialty
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default SpecialtyForm;
